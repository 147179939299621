import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  AuthPage,
  Icons,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/reset.css";

import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { authProvider } from "./authProvider";
import { RegisterPage } from "components/auth/RegisterPage";
import { LoginPage } from "components/auth";
import {
  CheckoutPage,
  DashboardPage,
  EditPartnerPage,
  OrderDetailsPage,
  OrdersPage,
  PersonalPage,
  ProductDetailsPage,
  ProductsPage,
  WishlistsPage,
} from "pages";
import { restDataProvider } from "dataProvider";
import { ConfigProvider, notification } from "antd";
import axios from "axios";
import { ResetPasswordPage } from "components/auth/ResetPasswordPage";

const { UserOutlined, AppstoreOutlined, FileOutlined, HeartOutlined } = Icons;
axios.defaults.withCredentials = true;
// axios.interceptors.request.use((config) => {
//   //config.headers.set("cookie", document.cookie);
//   return config;
// });
function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  notification.config({
    placement: "topLeft",
  });
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#c30900",
          colorPrimaryHover: "#fd1100",
          colorLink: "#c30900",
          colorLinkHover: "#fd1100",
        },
      }}
    >
      <RefineKbarProvider>
        <Refine
          dataProvider={restDataProvider(process.env.REACT_APP_API_URL ?? "")}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          DashboardPage={DashboardPage}
          catchAll={<ErrorComponent />}
          options={{
            syncWithLocation: true,
          }}
          resources={[
            {
              name: "products",
              list: ProductsPage,
              show: ProductDetailsPage,
              icon: <AppstoreOutlined />,
              options: {
                label: "Товари",
              },
            },
            {
              name: "orders",
              list: OrdersPage,
              show: OrderDetailsPage,
              icon: <FileOutlined />,
              options: {
                label: "Замовлення",
              },
            },

            {
              name: "personal",
              list: PersonalPage,
              edit: EditPartnerPage,
              icon: <UserOutlined />,
              options: {
                label: "Профіль",
              },
            },
          ]}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          routerProvider={{
            ...routerProvider,
            routes: [
              {
                path: "/register",
                element: <RegisterPage />,
              },
              {
                path: "/reset-password/:link",
                element: <ResetPasswordPage />,
              },
              {
                path: "/checkout",
                element: <CheckoutPage />,
                layout: true,
              },
              {
                path: "/wishlists",
                element: <WishlistsPage />,
                layout: true,
              },
            ],
          }}
          authProvider={authProvider}
          LoginPage={LoginPage}
          i18nProvider={i18nProvider}
        />
      </RefineKbarProvider>
    </ConfigProvider>
  );
}

export default App;
